<template>
  <v-container fill-height  fluid>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading :title="title">
          <base-btn v-if="users.length>0" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <base-data-table 
          v-model="sortBy"
          :headers="headers" 
          :items="users" 
          :showDialog="dialog"
          showEnabledField
          :hideNewItemButton="!canAddUsers"
          @loadDataTable="toList"
          @newItem="open"
        > 
          <template v-slot:item.options="{ item }">
            <base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Datos"></base-icon-btn>

            <template v-if="item.canDisable">
              <base-icon-btn v-if="item.enabled" color="red darken-1" @click="enableDisableItem(item, 2)" icon="mdi-cancel" tooltip="Desactivar"></base-icon-btn>
              <base-icon-btn v-else color="green darken-1" @click="enableDisableItem(item, 1)" icon="mdi-check" tooltip="Activar"></base-icon-btn>
					  </template>
            <base-icon-btn color="secondary darken-1" @click="showBranchOffices(item)" icon="mdi-key-variant" tooltip="Asignar Sucursales"></base-icon-btn>
            <base-icon-btn v-if="item.canChangeRoles" color="info darken-1" @click="showRoles(item)" icon="mdi-shield-account" tooltip="Asignar Roles"></base-icon-btn>
          </template>

          <template v-slot:content>
            <base-form-simple v-if="initialUserForm" ref="UserForm"
              :formTitle="formTitle"
              :formLoading="formLoading"
              hideSaveButton
              @cancelButton="close"
            >
              <template v-slot:formContent>
                <v-container class="no-padding">
                  <transition name="slide-fade" mode="out-in">
                    <v-row v-if="selectForm" key="choice" align="center">
                      <v-col cols="12">
                        <v-hover v-slot:default="{ hover }">
                          <base-form-container
                            :elevation="hover ? 10 : 0"
                            title="Registro Rápido"
                            color="grey lighten-4"
                            titleClasses="grey lighten-3"
                            outlined
                            tile
                          >
                            <template v-slot:content>
                              <p class="text-justify">Para un registro rápido ingrese el código <strong>Global Desk</strong> de la persona.</p>
                              <v-row justify="center" align="center" no-gutters>
                                <v-col cols="9" sm="4">
                                  <base-text-field v-model="gdpiCode" label="Código Global Desk" counter maxlength="30" 
                                  prepend-icon="mdi-shield-account"
                                  :rules="[commonValRules.required, validationRules.gdpiCodeRules.counter]" required clearable></base-text-field>
                                </v-col>
                                <v-col cols="3" sm="3" align="end">
                                  <base-btn enableSmallBtn color="secondary" @click="verifyUserByGDPI()" icon="mdi-account-check" btnText="Verificar"></base-btn>
                                </v-col>

                                <template v-if="searchPerson.exists">
                                  <v-col cols="9" sm="7">
                                    <base-text-field v-model="searchPerson.fullName" label="Nombre Completo" readonly></base-text-field>
                                  </v-col>
                                  <v-col cols="3" sm="3" align="end">
                                    <base-btn enableSmallBtn color="primary" @click="affiliate()" icon="mdi-account-plus" btnText="Registrar"></base-btn>
                                  </v-col>
                                </template>
                              </v-row>
                            </template>
                          </base-form-container>
                        </v-hover>
                      </v-col>
                      <v-col cols="12">
                        <v-hover v-slot:default="{ hover }">
                          <base-form-container
                            :elevation="hover ? 10 : 0"
                            title="Crear Cuenta"
                            color="grey lighten-4"
                            titleClasses="grey lighten-3"
                            outlined
                            tile
                          >
                            <template v-slot:content>
                              <p class="text-justify">Cree una cuenta <strong>Global Desk</strong> para la persona con la finalidad de registrarlo como usuario de su empresa.</p>
                              <div class="text-center">
                                <base-btn color="primary" @click="{selectForm = false; searchPerson.exists = false;}" icon="mdi-page-next-outline" btnText="Ir al formulario"></base-btn>
                              </div>
                            </template>
                          </base-form-container>
                        </v-hover>
                      </v-col>
                    </v-row>

                    <v-row v-else key="chosen" justify="center" align="center">
                      <v-col cols="12" align="center">
                        <p class="mb-0">Antes de crear una cuenta <strong>Global Desk</strong> necesitamos verificar los datos de la persona, por favor ingrese el correo electrónico de la persona y presione el botón <b>verificar</b>.</p>
                      </v-col>
                      <v-col cols="10" sm="6" md="6">
                        <base-text-field v-model="searchPerson.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength" 
                        :rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required></base-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2" align="center">
                        <base-btn enableSmallBtn color="secondary" @click="verifyUserByEmail()" icon="mdi-account-check" btnText="Verificar"></base-btn>
                      </v-col>

                      <template v-if="searchPerson.exists">
                        <v-col cols="10" sm="7" md="7">
                          <base-text-field v-model="searchPerson.fullName" label="Nombre Completo" readonly></base-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" md="2">
                          <base-btn enableSmallBtn color="primary" @click="affiliate()" icon="mdi-account-plus" btnText="Registrar"></base-btn>
                        </v-col>
                      </template>
                    </v-row>
                  </transition>
                </v-container>
              </template>
            </base-form-simple>

            <base-form-stepper v-else ref="UserForm"
              :formTitle="formTitle"
              :step="step"
              :steps="steps"
              :formLoading="formLoading"
              :hideSaveButton="hideSaveButton"
              
              @prevStepButton="step--"
              @nextStepButton="step++"
              @cancelButton="close"
              @saveButton="save"
            >
              <template v-slot:before>
                <v-container class="no-padding">
                  <v-row no-gutters>
                    <template v-if="editData">
                      <v-col cols="12" sm="4" md="4">
                        <base-text-field v-model="editedItem.identifier" label="Código" readonly></base-text-field>
                      </v-col>
                      <v-col cols="12" sm="8" md="8">
                        <base-text-field v-model="fullName" label="Usuario" readonly></base-text-field>
                      </v-col>
                    </template>
                    <v-col v-else cols="12">
                      <base-text-field v-model="fullName" label="Usuario" readonly></base-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:formContent-1>
                <template v-if="editData">
                  <v-row justify="center">
                    <v-col cols="12" sm="4" md="4">
                      <base-select v-model="editedItem.nationalityId" :items="nationalities" label="Nacionalidad" disabled></base-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <base-text-field v-model="editedItem.name" label="Nombres" disabled></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <base-text-field v-model="editedItem.surnames" label="Apellidos" disabled></base-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <base-date-picker-dialog birthdateMode v-model="editedItem.birthdate" label="Fecha de Nacimiento" :maxlength="person.birthdateMaxLength" disabled></base-date-picker-dialog>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <base-label text="Género" disabled>
                        <v-radio-group class="mt-0 pt-0"
                          v-model="editedItem.gender"
                          row
                          disabled
                        >
                          <v-radio v-for="gender in genders" :key="gender.id"
                            :label="gender.visualName"
                            :value="gender.id"
                            color="info"
                          ></v-radio>
                        </v-radio-group>
                      </base-label>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <base-select v-model="editedItem.documentId" :items="documents" label="Tipo Documento" disabled></base-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <base-number-field fullWidth v-model="editedItem.documentNumber" label="Número Documento" disabled></base-number-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <base-text-field v-model="editedItem.documentComplement" label="Complemento" disabled></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <base-select v-model="editedItem.documentExtensionId" :items="documentExtensions" label="Extensión" disabled></base-select>
                    </v-col>

                    <v-col cols="12" sm="7" md="7">
                      <base-number-field fullWidth v-model="editedItem.cellPhoneNumber" label="Número Celular" disabled></base-number-field>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" >
                      <!-- <v-alert v-if="editedItem.cellPhoneNumberVerified"
                        text
                        dense
                        elevation="2"
                        type="success"
                        border="left"
                        justify="center"
                        align="center"
                      >
                        Celular Verificado
                      </v-alert>
                      <v-alert v-else
                        text
                        dense
                        elevation="2"
                        type="error"
                        border="left"
                        justify="center"
                        align="center"
                      >
                        Celular No Verificado
                      </v-alert> -->
                    </v-col>

                    <v-col cols="12" sm="8" md="8">
                      <base-text-field v-model="editedItem.email" label="Correo Electrónico" disabled></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-alert v-if="editedItem.emailVerified"
                        text
                        dense
                        elevation="2"
                        type="success"
                        border="left"
                        justify="center"
                        align="center"
                      >
                        E-mail Verificado
                      </v-alert>
                      <v-alert v-else
                        text
                        dense
                        elevation="2"
                        type="error"
                        border="left"
                        justify="center"
                        align="center"
                      >
                        E-mail No Verificado
                      </v-alert>
                    </v-col>
                  </v-row>
                </template>

                <template v-else>
                  <v-row justify="center">
                    <v-col cols="12" sm="4" md="4">
                      <base-select v-model="editedItem.nationalityId" :items="nationalities" label="Nacionalidad" 
                      :rules="[commonValRules.required]" required></base-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <base-text-field v-model="editedItem.name" label="Nombres" counter :maxlength="person.nameMaxLength" 
                      :rules="[commonValRules.required, personValidationRules.nameRules.counter]" required></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <base-text-field v-model="editedItem.surnames" label="Apellidos" counter :maxlength="person.surnamesMaxLength" 
                      :rules="[commonValRules.required, personValidationRules.surnamesRules.counter]" required></base-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <base-date-picker-dialog birthdateMode v-model="editedItem.birthdate" label="Fecha de Nacimiento" counter :maxlength="person.birthdateMaxLength" 
                      :rules="[commonValRules.required, personValidationRules.birthdateRules.counter]" required></base-date-picker-dialog>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <base-label text="*Género">
                        <v-radio-group class="mt-0 pt-0"
                          v-model="editedItem.gender"
                          row
                          :rules="[commonValRules.required]" required
                        >
                          <v-radio v-for="gender in genders" :key="gender.id"
                            :label="gender.visualName"
                            :value="gender.id"
                            color="info"
                          ></v-radio>
                        </v-radio-group>
                      </base-label>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <base-select v-model="editedItem.documentId" :items="documents" label="Tipo Documento"
                      :rules="[commonValRules.required]" required></base-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <base-number-field fullWidth v-model="editedItem.documentNumber" label="Número Documento" counter :maxlength="person.documentNumberMaxLength"
                      :rules="[personValidationRules.documentNumberRules.counter]" required></base-number-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <base-text-field v-model="editedItem.documentComplement" label="Complemento" counter :maxlength="person.documentComplementMaxLength"
                      :rules="[personValidationRules.documentComplementRules.counter]"></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <base-select v-model="editedItem.documentExtensionId" :items="documentExtensions" label="Extensión" 
                      :rules="documentExtensionIdRules" :required="documentExtensionIsRequired" :disabled="!documentExtensionIsRequired"></base-select>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <base-number-field fullWidth v-model="editedItem.cellPhoneNumber" label="Número Celular" counter :maxlength="person.cellPhoneNumberMaxLength"
                      :rules="[personValidationRules.cellPhoneNumberRules.counter]" required></base-number-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <base-text-field v-model="editedItem.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength"  
                      :rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required disabled></base-text-field>
                    </v-col>
                  </v-row>
                </template>
              </template>

              <template v-slot:formContent-2>
                <v-row v-if="editData">
                  <v-col cols="12" sm="6" md="6">
                    <base-select v-model="editedItem.countryId" :items="countries" label="País" disabled></base-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-select v-model="editedItem.cityId" :items="availableCities" label="Ciudad" disabled></base-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <base-text-field v-model="editedItem.address" label="Dirección" disabled></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-number-field fullWidth v-model="editedItem.phoneNumber" label="Número Télefono" disabled></base-number-field>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col cols="12" sm="6" md="6">
                    <base-select v-model="editedItem.countryId" :items="countries" label="País" @change="loadCities"
                    :rules="[commonValRules.required]" required></base-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-select v-model="editedItem.cityId" :items="availableCities" label="Ciudad" 
                    :rules="[commonValRules.required]" required></base-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <base-text-field v-model="editedItem.address" label="Dirección" counter :maxlength="person.addressMaxLength"  
                    :rules="[commonValRules.required, personValidationRules.addressRules.counter]" required></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-number-field fullWidth v-model="editedItem.phoneNumber" label="Número Télefono" counter :maxlength="person.phoneNumberMaxLength"
                    :rules="[personValidationRules.phoneNumberRules.counter]"></base-number-field>
                  </v-col>
                </v-row>

              </template>
            </base-form-stepper>
          </template>

        </base-data-table>

        <v-dialog v-model="showAccessDataModal" persistent scrollable max-width="500px">
          <base-form-container
            title="Solicitud realizada correctamente"
          >
            <template v-slot:content>
              <v-row justify="center">
                <v-col cols="12" align="center">
                  <p class="text-justify">Se envió un correo electrónico a la dirección <strong>{{editedAccessData.email}}</strong> con los pasos a seguir para que el usuario pueda acceder a la plataforma <strong>GAVCOM</strong>.</p>
                  <v-avatar>
                    <v-icon x-large color="blue darken-2">mdi-email-send-outline</v-icon>
                  </v-avatar>
                  <p class="mt-4 text-center">¡¡Muchas gracias por la confianza en nuestro equipo de trabajo!!</p>
                </v-col>
              </v-row>
            </template>

            <template v-slot:formActions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" depressed @click="closeAccessDataModal()">¡Comprendido!</v-btn>
            </template>
          </base-form-container>
        </v-dialog>

        <v-dialog v-model="showBranchesModal" persistent scrollable max-width="800px">
          <base-form-simple :formTitle="'Asignación de sucursales'"
            :formLoading="branchesModalLoading"
            @cancelButton="closeBranchOffices"
            @saveButton="saveBranchOffices"
          >
            <template v-slot:formContent>
              <v-container class="no-padding" fluid>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <base-text-field v-model="editedItem.identifier" label="Código" readonly></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <base-text-field v-model="editedItem.fullName" label="Usuario" readonly></base-text-field>
                  </v-col>
                </v-row>

                <base-draggable v-bind:pair="branchOfficeLists" v-on:onChange="onBranchOfficesChanged" :group="'branchOffices'" :firstIcon="'mdi-lock'" 
                  :secondIcon="'mdi-lock-open-outline'" 
                >
                </base-draggable>
              </v-container>
            </template>
          </base-form-simple>
        </v-dialog>

        <v-dialog v-model="showRolesModal" persistent scrollable max-width="800px">
          <base-form-simple :formTitle="'Asignación de roles'"
            :formLoading="rolesModalLoading"
            @cancelButton="closeRoles"
            @saveButton="saveRoles"
          >
            <template v-slot:formContent>
              <v-container class="no-padding" fluid>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <base-text-field v-model="editedItem.identifier" label="Código" readonly></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <base-text-field v-model="editedItem.fullName" label="Usuario" readonly></base-text-field>
                  </v-col>
                </v-row>

                <base-draggable v-bind:pair="roleLists" v-on:onChange="onRolesChanged" :title="'Roles'" :group="'roles'" :firstIcon="'mdi-shield-lock'" 
                  :secondIcon="'mdi-shield-check-outline'" 
                >
                </base-draggable>
              </v-container>
            </template>
          </base-form-simple>
        </v-dialog>
      </v-col>
    </v-row>

    <base-pdf-viewer :show="showPdfViewer" :title="title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
  </v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
	mixins: [MainExternalData, PersonData],

	data() {
		return {
      title: 'Usuarios',
      step: 1,
      steps:[
        {value: 1, text:'Datos Personales'},
        {value: 2, text:'Domicilio'}
      ],
			sortBy: 'fullName',
			users: [],
			headers: [
        { text: "Opciones", value: "options", sortable: false },
        { text: "Código", value: "identifier" },
        { text: "Usuario", value: "fullName" },
        { text: "Nacionalidad", value: "nationality" },
        { text: "Número Celular", value: "cellPhoneNumber", sortable: false },
        { text: "Estado", value: "enabled" }
			],
      countries:[],
      nationalities:[],
      cities:[],
      availableCities:[],
      documentExtensionIsRequired: false,
			editedItem: {
        id: 0,
        identifier: '',
        nationalityId: '',
        fullName:'',
        name: '',
        surnames: '',
        gender:'',
				birthdate: '',
        documentId: '',
        documentNumber: '',
        documentComplement: '',
        documentExtensionId: '',
        countryId: '',
				cityId: '',
        address: '',
        phoneNumber: '',
        cellPhoneNumber: '',
        email: '',
        emailVerified: false,
        cellPhoneNumberVerified: false,
        enabled: false,
      },
      defaultItem: {
        id: 0,
        identifier: '',
        nationalityId: '',
        fullName:'',
        name: '',
        surnames: '',
        gender:'',
				birthdate: '',
        documentId: '',
        documentNumber: '',
        documentComplement: '',
        documentExtensionId: '',
        countryId: '',
				cityId: '',
        address: '',
        phoneNumber: '',
        email: '',
        cellPhoneNumber: '',
        emailVerified: false,
        cellPhoneNumberVerified: false,
        enabled: false,
      },

      searchPerson: {
        gdpi: '',
        email: '',
        fullName: '',
        exists: false
      },
      defaultSearchPerson: {
        gdpi: '',
        email: '',
        fullName: '',
        exists: false
      },

      canAddUsers: false,
      gdpiCode:'',
      initialUserForm: true,
      editData: false,
      dialog: false,
      formLoading: false,
      selectForm: true,
      hideSaveButton:false,
      editedAccessData: {
        id: 0,
        email: '',
        identifier: '',
        password: '',
      },
      defaultAccessData: {
        id: 0,
        email: '',
        identifier: '',
        password: '',
      },
      showAccessDataModal: false,

      //BRANCH OFFICES
      branchesAvailable: [],
      branchesAssigned: [],
      branchesModalLoading: false,
      showBranchesModal: false,

      //ROLES
      rolesAvailable: [],
      rolesAssigned: [],
      rolesModalLoading: false,
      showRolesModal: false,

      validationRules: {
        gdpiCodeRules: {
          counter: value => (!!value && value.length <= 30) || 'No debe tener más de 30 caracteres.',
				},
      },
      
      //PDF REPORT
			showPdfViewer: false,
			reportFile:{
				fileContent:'',
				fileName:''
			},
			defaultReportFile:{
				fileContent:'',
				fileName:''
			},
		}
  },
  
  computed: {
    formTitle() {
      return this.editData ? "Información del registro" : "Nuevo registro";
    },
    
    fullName() {
      return this.editedItem.name + ' ' + this.editedItem.surnames;
    },

    documentExtensions() {
			return this.editedItem.documentId == this.$Const.DOCUMENT_TYPE.CI ? this.extensions : [];
    },

    documentExtensionIdRules(){
      var rules=[];
			if (this.editedItem.documentId === this.$Const.DOCUMENT_TYPE.CI) {
        rules.push(this.commonValRules.required);
        this.documentExtensionIsRequired = true;
      }
      else {
        this.editedItem.documentExtensionId = '';
        this.documentExtensionIsRequired = false;
      }
			return rules;
		},

    branchOfficeLists(){
      const result = [this.branchesAvailable, this.branchesAssigned];
      return result;
    },

    roleLists(){
      const result = [this.rolesAvailable, this.rolesAssigned];
      return result;
    },
  },

	created() {
    this.listOfParameters();
    this.listOfCountries();
    this.listOfNationalities();
    this.listOfCities();
		this.toList();
  },

	methods: {
    onBranchOfficesChanged(firstList, secondList){
      this.branchesAvailable = firstList; 
      this.branchesAssigned = secondList;
    },

    onRolesChanged(firstList, secondList){
      this.rolesAvailable = firstList; 
      this.rolesAssigned = secondList;
    },

    async listOfParameters(){
      let me=this;
			await me.getListOfParameterValuesResponse([me.$Const.PARAMETERS.DOCUMENTS, me.$Const.PARAMETERS.EXTENSIONS]).then(data => {
        if (data !== undefined) {
          let documentArray = data.find(x => x.id == me.$Const.PARAMETERS.DOCUMENTS).values;
          documentArray.map(function(x){
            me.documents.push({text:x.visualName, value:x.id});
          });

          let extensionsArray = data.find(x => x.id == me.$Const.PARAMETERS.EXTENSIONS).values;
          extensionsArray.map(function(x){
            me.extensions.push({text:x.visualName, value:x.id});
          });
        }
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    async listOfCountries(){
      let me=this;
			var countriesArray=[];
			await me.getObjectResponse('api/Parameter/Countries', me.baseRequest).then(data => {
				if (data !== undefined) {
					countriesArray = data.countries; 
					countriesArray.map(function(x){
            me.countries.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    async listOfNationalities(){
      let me=this;
			var nationalitiesArray=[];
			await me.getObjectResponse('api/Parameter/Nationalities', me.baseRequest).then(data => {
				if (data !== undefined) {
					nationalitiesArray = data.nationalities; 
					nationalitiesArray.map(function(x){
            me.nationalities.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },
    
    async listOfCities(){
      let me=this;
			await me.getObjectResponse('api/Parameter/Cities', me.baseRequest).then(data => {
				if (data !== undefined) {
					me.cities = data.cities; 
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    loadCities(){
      this.availableCities=[];
      this.editedItem.cityId='';
      if (!this.editedItem.countryId || this.editedItem.countryId === 0) {
        return;
      }

      const available = this.cities.filter(x => x.countryId === this.editedItem.countryId);
      var result =[];
      available.map(function(x){
        result.push({text:x.visualName, value:x.id});
      });
      this.availableCities=result
    },

		async toList(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.users=[];
        await me.getObjectResponse('api/User/GetAll', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.users = data.users; 
            me.canAddUsers = data.canAddUsers;
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },
    
    async listOfBranchOfficesPerUser(userId){
      let me=this;
      var result = false;
      var branchOfficesArray=[];
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId,
        'userToFind': userId,
      };
			await me.getObjectResponse('api/UserBranchOffice/GetAllBranchOffices', request).then(data => {
				if (data !== undefined) {
          branchOfficesArray = data.branchOffices; 
					branchOfficesArray.map(function(x){
						if (x.assigned) {
              me.branchesAssigned.push(x);
            }
            else{
              me.branchesAvailable.push(x);
            }
          });
          result = true;
        }
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
      return result;
		},

		async listOfRolesPerUser(userId){
      let me=this;
      var result = false;
      var rolesArray=[];
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId,
        'userToFind': userId,
      };
			await me.getObjectResponse('api/UserRole/GetAllRoles', request).then(data => {
				if (data !== undefined) {
          rolesArray = data.roles; 
					rolesArray.map(function(x){
            if (x.assigned) {
              me.rolesAssigned.push(x);
            }
            else{
              me.rolesAvailable.push(x);
            }
          });
          result = true;
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
      return result;
    },
		
		async viewItem(item) {
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getObjectResponse('api/User/GetById', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.editedItem = Object.assign({}, data.user);
          me.loadCities();
          me.editedItem.cityId = data.user.cityId
          me.initialUserForm = false;
          me.editData = true;
          me.dialog = true;
          me.hideSaveButton = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    open(){
      this.initialUserForm = true;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.clean();
    },
    
    clean(){
      this.step = 1;
      this.editData = false;
      this.$refs.UserForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.searchPerson = Object.assign({}, this.defaultSearchPerson);
      this.gdpiCode = '';
      this.selectForm = true;
      this.hideSaveButton = false;
    },

    async verifyUserByEmail(){
      let me=this;
      if (!me.$refs.UserForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'email': me.searchPerson.email,
        };
        me.formLoading=true;
        await me.getObjectResponse('api/User/CheckIfExistsByEmail', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.searchPerson.gdpi = data.gdpi;
          me.searchPerson.fullName = data.fullName;
          me.searchPerson.exists = data.exists;
          if (!data.exists) {
            me.initialUserForm = false;
            me.editedItem.email = me.searchPerson.email;
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async verifyUserByGDPI(){
      let me=this;
      if (!me.$refs.UserForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'gdpi': me.gdpiCode,
        };
        me.formLoading=true;
        await me.getObjectResponse('api/User/CheckIfExistsByGDPI', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.searchPerson.gdpi = me.gdpiCode;
          me.searchPerson.fullName = data.fullName;
          me.searchPerson.exists = data.exists;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async affiliate(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'gdpi': me.searchPerson.gdpi,
        };
        me.formLoading=true;
        await me.getObjectResponse('api/User/RegisterUsingGDPI', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.addOrUpdateListItem(data.user);
          me.showSuccessModal();
          me.close();
          me.editedAccessData.identifier = data.user.identifier;
          me.editedAccessData.email = data.user.email;
          me.showAccessDataModal = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async save() {
      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          
          'nationalityId': me.editedItem.nationalityId,
          'name': me.editedItem.name,
          'surnames': me.editedItem.surnames,
          'gender': me.editedItem.gender,
          'birthdate': me.editedItem.birthdate,
          'documentId': me.editedItem.documentId,
          'documentNumber': me.editedItem.documentNumber,
          'documentComplement': me.editedItem.documentComplement,
          'documentExtensionId': me.editedItem.documentExtensionId,
          'countryId': me.editedItem.countryId,
          'cityId': me.editedItem.cityId,
          'address': me.editedItem.address,
          'phoneNumber': me.editedItem.phoneNumber,
          'cellPhoneNumber': me.editedItem.cellPhoneNumber,
          'email': me.editedItem.email
        };
        await me.getObjectResponse('api/User/Create', request).then(data => {
          if (data == undefined) {
            return;
          }
          me.addOrUpdateListItem(data.user);
          me.showSuccessModal();
          me.close();
          me.editedAccessData.identifier = data.user.identifier;
          me.editedAccessData.email = data.user.email;
          me.showAccessDataModal = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
        
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    addOrUpdateListItem(item){
			let me=this;
			if (!!me.users.find((x) => x.id === item.id)) {
				const index = me.users.findIndex((x) => x.id === item.id)
				me.users.splice(index, 1, item)
			} else {
				me.users.push(item);
			}
		},

    async enableDisableItem(item, action){
			let me = this;
      if (!await me.showEnableDisableConfirmDialog(action, `el usuario <strong>${item.fullName}</strong>`)) {
				return;
			}

			if (action==1) {
				me.enable(item);
			}
			else if (action==2) {
				me.disable(item);
			}
    },

    async enable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/User/Enable', request).then(data => {
          if (data) {
            item.enabled = true;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async disable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/User/Disable', request).then(data => {
          if (data) {
            item.enabled = false;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeAccessDataModal() {
      this.editedAccessData = Object.assign({}, this.defaultAccessData);
      this.showAccessDataModal = false;
    },

    //BRANCH OFFICES
    async showBranchOffices(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        if (!await me.listOfBranchOfficesPerUser(item.id)) {
          return;
        }
        me.editedItem.id = item.id;
        me.editedItem.identifier = item.identifier;
        me.editedItem.fullName = item.fullName;
        me.showBranchesModal = true;
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeBranchOffices(){
      this.showBranchesModal = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.branchesAvailable = [];
      this.branchesAssigned = [];
    },

    async saveBranchOffices(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.branchesModalLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'userToAssign': me.editedItem.id,
          'branchOffices': me.branchesAssigned.map(x => x.id),
        };
        await me.getBaseResponse('api/UserBranchOffice/AssignBranchOffices', request).then(data => {
          if (data) {
            me.closeBranchOffices();
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.branchesModalLoading=false
      }
    },

    //ROLES
		async showRoles(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        if (!await me.listOfRolesPerUser(item.id)) {
          return;
        }
        me.editedItem.id = item.id;
        me.editedItem.identifier = item.identifier;
        me.editedItem.fullName = item.fullName;
        me.showRolesModal = true;
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

		closeRoles(){
      this.showRolesModal = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.rolesAvailable = [];
      this.rolesAssigned = [];
    },
    
    async saveRoles(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.rolesModalLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'userToAssign': me.editedItem.id,
          'roles': me.rolesAssigned.map(x => x.id),
        };
        await me.getBaseResponse('api/UserRole/AssignRoles', request).then(data => {
          if (data) {
            me.closeRoles();
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.rolesModalLoading=false
      }
    },

    //PDF REPORT
    async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/User/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
		},
	},
}
</script>

<style scoped>
/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all .6s ease;
}

.slide-fade-leave-active {
  transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>